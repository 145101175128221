import React from 'react';

import {
    HeroBanner,
    CaseHeader,
    Divider,
    TextContent,
    Gallery,
    GalleryLayout,
    Team,
    Testimonial,
} from 'modules/references';
import { sendCaseStudyPageView } from 'utils/analytics';

import socialPreview from '../../../static/social-previews/wwf-ghostdiver.png';
import Seo from '../../components/SEO';

import { ReferenceCategory } from '../../constants';

const CASE_ID = 'wwf-ghostdiver';
const MODULE_NAME = 'wwf-ghostdiver';
const IMAGE_PATH = `references/case-studies/${MODULE_NAME}`;

const colors = {
    bgLight: '#F3F7F7',
    bgMain: '#D2E0E0',
    bgDark: '#008789',
};

const team = [
    { department: 'references.case.team.management', names: ['Marek Mouček'] },
    {
        department: 'references.case.team.design',
        names: ['Anna Meshcheryakova'],
    },
    {
        department: 'references.case.team.ux',
        names: ['Markéta Hejná'],
    },
    {
        department: 'references.case.team.testing',
        names: ['Anna Zmeevskaja'],
    },
    {
        department: 'references.case.team.web',
        names: ['Ivan Harašta'],
    },
    {
        department: 'references.case.team.backend',
        names: ['Jiří Zdvomka'],
    },
    {
        department: 'references.case.team.flutter',
        names: ['David Bilík', 'Michal Pešek'],
    },
    {
        department: 'references.case.team.devops',
        names: ['Ondřej Štorc'],
    },
];

const WWFGhostdiver = () => (
    <>
        <Seo image={socialPreview} pageId={CASE_ID} handleMetadata={sendCaseStudyPageView} />
        <CaseHeader />
        <HeroBanner
            content={{
                projectName: `case.${CASE_ID}.hero.project`,
                projectTitle: `case.${CASE_ID}.hero.title`,
                projectYear: `case.${CASE_ID}.hero.year`,
                client: `case.${CASE_ID}.hero.client`,
                image: {
                    src: `${IMAGE_PATH}/hero-image.png`,
                    style: { height: '70%', width: '100%', marginTop: '2rem' },
                    imgStyle: { objectFit: 'contain', objectPosition: 'center bottom' },
                },
            }}
            backgroundImage={{
                src: `${IMAGE_PATH}/hero-background.png`,
                imgStyle: { objectFit: 'contain', objectPosition: 'bottom' },
            }}
            background={colors.bgLight}
            headerTheme="dark"
            textColor="#000"
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section1.name`,
                title: `case.${CASE_ID}.section1.title`,
                text: `case.${CASE_ID}.section1.text`,
                category: ReferenceCategory.DIGITALIZATION,
                outputs: [
                    'references.case.output.ux',
                    'references.case.output.design',
                    'references.case.output.android',
                    'references.case.output.ios',
                    'references.case.output.web',
                    'references.case.output.testing',
                ],
            }}
        />
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.TWO_THIRDS}
            content={[
                {
                    background: colors.bgMain,
                    image: {
                        src: `${IMAGE_PATH}/01-1.png`,
                        style: {
                            width: '80%',
                        },
                        imgStyle: {
                            objectPosition: 'center',
                        },
                    },
                },
                {
                    image: {
                        src: `${IMAGE_PATH}/01-2.jpg`,
                        imgStyle: {
                            objectPosition: 'center',
                            objectFit: 'cover',
                        },
                    },
                    text: `case.${CASE_ID}.photo1.text`,
                    textColor: '#fff',
                    textSize: '0.875rem',
                },
            ]}
        />
        <Divider height={'1.25rem'} />
        <Gallery
            headerTheme="light"
            layout={GalleryLayout.EQUAL}
            content={[
                {
                    background: colors.bgDark,
                    image: {
                        src: `${IMAGE_PATH}/02.png`,
                        imgStyle: {
                            objectFit: 'cover',
                            objectPosition: 'center',
                        },
                    },
                },
            ]}
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section4.name`,
                title: `case.${CASE_ID}.section4.title`,
                text: `case.${CASE_ID}.section4.text`,
            }}
        />

        <Gallery
            headerTheme="light"
            layout={GalleryLayout.ONE_HALF}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/03-1.jpg`,
                        imgStyle: {
                            objectPosition: 'center',
                            objectFit: 'cover',
                        },
                    },
                    text: `case.${CASE_ID}.photo2.text`,
                    textColor: '#fff',
                    textSize: '0.875rem',
                },
                {
                    background: colors.bgMain,
                    image: {
                        src: `${IMAGE_PATH}/03-2.png`,
                        imgStyle: {
                            objectPosition: 'center',
                            objectFit: 'cover',
                        },
                    },
                },
            ]}
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section6.name`,
                title: `case.${CASE_ID}.section6.title`,
                text: `case.${CASE_ID}.section6.text`,
            }}
        />
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.ONE_THIRD}
            content={[
                {
                    background: colors.bgMain,

                    image: {
                        src: `${IMAGE_PATH}/04-1.png`,
                        imgStyle: {
                            objectPosition: 'center',
                            objectFit: 'contain',
                        },
                    },
                },
                {
                    image: {
                        src: `${IMAGE_PATH}/04-2.jpg`,
                        imgStyle: {
                            objectPosition: 'center',
                            objectFit: 'cover',
                        },
                    },
                    text: `case.${CASE_ID}.photo3.text`,
                    textColor: '#fff',
                    textSize: '0.875rem',
                },
            ]}
        />

        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section8.name`,
                title: `case.${CASE_ID}.section8.title`,
                text: `case.${CASE_ID}.section8.text`,
            }}
        />

        <Gallery
            headerTheme="light"
            layout={GalleryLayout.EQUAL}
            content={[
                {
                    background: colors.bgDark,
                    image: {
                        src: `${IMAGE_PATH}/05.png`,
                        style: { height: '90%', width: '90%', margin: 'auto', marginBottom: 0 },
                        imgStyle: {
                            objectFit: 'contain',
                        },
                    },
                },
            ]}
        />
        <Testimonial
            headerTheme="dark"
            background="#F9F9F9"
            content={{
                quote: `case.${CASE_ID}.testimonial.text`,
                person: {
                    name: `case.${CASE_ID}.testimonial.name`,
                    role: `case.${CASE_ID}.testimonial.position`,
                    image: {
                        src: `${IMAGE_PATH}/img_person_placeholder.jpg`,
                    },
                },
            }}
            type="PROFILE_RIGHT"
        />
        <Team headerTheme="dark" content={team} />
    </>
);

export default WWFGhostdiver;
